<template>
  <div class="" style="height: 100%; width: 100%;">
    <div class="py-4 d-flex flex-column align-center fill-height">
      <router-link
          :to="{name: 'Home'}"
          class="logo-link-solo mb-3"
      >
        <img
            src="@/assets/paperify-logo-icon-black-and-white.png"
            class="logo-icon"
        />
      </router-link>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {user} from "@/store/user.store";

export default {
  name: "SiteNav",
  components: {},
  props: {
    isOpen: Boolean,
    isMini: Boolean,
    bottom: Boolean,
  },
  data() {
    return {
      foo: 42,
    }
  },
  computed: {
    user() {
      return user
    },
    ...mapGetters([

      "entityType",
    ]),
    ...mapGetters("user", [
      "userId",
    ]),
  },

  methods: {
    ...mapMutations([
      "snackbar",
    ]),
    ...mapActions([]),


  },
  created() {
  },
  mounted() {
  },
  watch: {}
}
</script>

<style scoped lang="scss">
$logo-link-height: 40px;

.logo-link-solo {
  .logo-icon {
    height: 45px;
    //margin-top: -5px;
  }
}

.logo-link {
  text-decoration: none;
  width: 151px;
  display: flex;
  align-items: center;
  //padding-left: 30px;
  .logo-icon {
    height: $logo-link-height;
    //margin-top: -5px;
  }

  .logo-text {
    //padding: 0 14px;

    padding-left: .3em;
    line-height: 1.2;
    //border-left: 1px solid #333;
    color: #000;


    font-family: Dosis;
    font-size: $logo-link-height * 0.75;
    font-weight: 500;
  }
}
</style>