<template>
  <v-footer  color="#fff" class="site-footer" style="padding:0 0 50px;">
    <v-container>
      <v-row>
        <v-col cols="12" sm="5" class="">
          <div>
            <img
                src="@/assets/logo-full-small.png"
                class="logo-icon mt-1 mb-2 colorizable"
                style="height: 25px;"
            />
          </div>
<!--          Paperify is a free index of the world's research ecosystem. It's-->
        Feedback: <a href="mailto:support@paperify.org">support@paperify.org</a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  name: "SiteFooter",
}
</script>

<style scoped lang="scss">
.site-footer {
  line-height: 1.8;
  padding-top: 10px !important;

  .body-2 {
    font-family: Inconsolata;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

img.site-footer-logo {
  width: 70px;
  opacity: 1;

}
</style>